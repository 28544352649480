import React from 'react';

const Home = () => (

      <div>
    <p>
    Inspired by a certain legendary New York music venue, 213 Park Avenue are a Bristol-based band who will take you back to the glory days of Max’s Kansas City and the energetic rock n roll of the New York Dolls, Ramones, Alice Cooper, David Bowie and more…
    </p>
        <img src="/images/213ParkAvenue2.jpg" alt="213 Park Avenue" className="photo-responsive" />
      </div>
    
    )
    
export default Home