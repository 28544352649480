import React from 'react';
import { NavLink } from 'react-router-dom';

const Navigation = () => (
    <nav>
        <ul>
          <li><NavLink exact activeClassName="current" to='/'>Home</NavLink></li>
          <li><NavLink exact activeClassName="current" to='/video'>Video</NavLink></li>
          <li><NavLink exact activeClassName="current" to='/photos'>Photos</NavLink></li>
          <li><NavLink exact activeClassName="current" to='/player'>Noise</NavLink></li>
          <li><NavLink exact activeClassName="current" to='/gigs'>Gigs</NavLink></li>
        </ul>
      </nav>
    )

export default Navigation
