import React from 'react';

const Footer = () => (
    <nav>
        <span>< a href="mailto:213parkavenue@gmail.com"><img src="/images/Email.png" alt="Email" height="30" width="30"/></a></span>
        <span><a href="https://www.facebook.com/213ParkAvenue/"><img src="/images/Facebook.png" alt="YouTube" height="30" width="30"/></a></span>
        <span><a href="https://www.youtube.com/channel/UCthruu4ypvg327dibvQbiUg"><img src="/images/YouTube.png" alt="YouTube" height="30" width="30"/></a></span>     
      </nav>
    )

export default Footer
