import React from 'react';
import AudioPlayer from "react-h5-audio-player";

const Player = () => (
  <AudioPlayer
    autoPlay
    src="/noise/Night Time Master.mp3"
    onPlay={e => console.log("onPlay")}
    // other props here
  />
);

export default Player