import React from 'react';
import './App.css';
import Navigation from './navigation.js';
import Main from './main.js'
import Footer from './footer.js';


   const App = () => (
       <React.Fragment>
       <div className='app'>
               <h1 style={{ color: 'DeepPink' }}>213 Park Avenue</h1>
               <Navigation />
               <Main />
               <Footer />
      </div>
      </React.Fragment>
    );
    
    export default App;