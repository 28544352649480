import React from 'react';


const Video = () => (
      <div className='video-responsive'>
        <iframe title="Personality Crisis" width="560" height="315" src="https://www.youtube.com/embed/tqSNQmdrG1E" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    )
    
export default Video
