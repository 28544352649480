import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import WebFont from 'webfontloader';
    
WebFont.load({
  google: {
    families: ['Lobster', 'cursive']
  }
});
    ReactDOM.render((
    <BrowserRouter>
      <App />
    </BrowserRouter>
    ), document.getElementById('root'));