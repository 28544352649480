import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './home.js';
import Video from './video.js';
import Photos from './photos.js';
import Player from './player.js';
import Gigs from './gigs.js';


const Main = () => (
      <Switch>
        <Route exact path='/' component={Home}></Route>
        <Route exact path='/video' component={Video}></Route>
        <Route exact path='/photos' component={Photos}></Route>
        <Route exact path='/player' component={Player}></Route>
        <Route exact path='/gigs' component={Gigs}></Route>
      </Switch>
    )
    
export default Main