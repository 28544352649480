import React from 'react';

const Gigs = () => (

      <div>
        <table>
            <tbody>
               <tr>
               <td>20th August 2020</td>
                <td>All by ourselves</td> 
                <td>The Horseshoe Downend</td>
                <td>more details ...</td>
                <td>&nbsp;</td>
            </tr>
            <tr>
                <td>10th April 2020</td>
                <td>Supporting the band <a href="https://www.facebook.com/bbqtband/" target ="_blank" rel="noopener noreferrer">BBQT</a> at</td> 
                <td><a href="https://www.thethunderbolt.net" target="_blank" rel="noopener noreferrer">The Thunderbolt Bristol, </a></td>
                <td>more details ...</td>
                <td>CANCELLED Covid-19</td>
            </tr>

                <tr>
                <td>4th March 2020</td>
                <td>Supporting the band HOW NICE at</td> 
                <td><a href="https://www.thethunderbolt.net" target="_blank" rel="noopener noreferrer">The Thunderbolt Bristol, </a></td>
                <td>more details <a href="https://www.facebook.com/events/179481429996389/" target="_blank" rel="noopener noreferrer">here</a>
                </td>
                <td>&nbsp;</td>
            </tr>
            <tr>
               <td>13th November 2019</td>
                <td>Headlining</td> 
                <td><a href="https://www.thethunderbolt.net" target="_blank" rel="noopener noreferrer">The Thunderbolt Bristol, </a></td>
                <td>more details ...</td>
                <td>&nbsp;</td>
            </tr>
            <tr>
               <td>16th October 2019</td>
                <td>All by ourselves</td> 
                <td>The Horseshoe Downend</td>
                <td>more details ...</td>
                <td>&nbsp;</td>
            </tr>
 
            <tr>
                <td>14th February 2019</td>
                <td>Supporting the band <a href="https://www.bugeyeband.co.uk" target ="_blank" rel="noopener noreferrer">Bugeye</a> at</td> 
                <td><a href="https://www.thethunderbolt.net" target="_blank" rel="noopener noreferrer">The Thunderbolt Bristol, </a></td>
                <td>more details <a href="https://www.thethunderbolt.net/hidden-events-source/2019/2/14/bugeye" target="_blank" rel="noopener noreferrer">here</a>
                </td>
                <td>&nbsp;<img src="/images/bugeye14022019.jpg" height="80" width="170" alt="Bugeye 14/02/2019" /></td>
            </tr>
            </tbody>
        </table>
        <p></p>
      </div>
    
    )
    
export default Gigs