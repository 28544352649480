import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';


class ImageCarousel extends Component {
    render() {
        return (
             <Carousel>
                <div>
                    <img src="/images/204825.JPG" alt="" />
                    
                </div>
                <div>
                    <img src="/images/204921.JPG" alt="" />
                    
                </div>
                <div>
                    <img src="/images/205817.JPG" alt="" />
                    
                </div>
                <div>
                    <img src="/images/210420.JPG" alt="" />
                    
                </div>
                <div>
                    <img src="/images/210541.JPG" alt="" />
                    
                </div>
                <div>
                    <img src="/images/211031.JPG" alt="" />
                    
                </div>
                <div>
                    <img src="/images/band.JPG" alt="" />
                    
                </div>
            </Carousel>

        );
    }
};

export default ImageCarousel